// @flow

import React from "react";
import {
    DefaultLayout,
    Layout,
    withPrivateRoute,
    BulkAssignments as _BulkAssignments,
} from "@containers";

/**
 * BulkAssignments
 */
const BulkAssignments = (props: *) => (
    <Layout {...props}>
        <DefaultLayout title="Bulkopdrachten">
            <_BulkAssignments />
        </DefaultLayout>
    </Layout>
);

export default withPrivateRoute(BulkAssignments);
